.App {
  display: grid;
  grid-template-rows: 1fr 4fr;
  grid-template-areas:
    "header"
    "body";
  text-align: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  background: #780206;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #061161, #780206);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #061161, #780206); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  

}

.App-header {
  grid-area: header;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

#tileContainer {
  grid-area: body;
  display: flex;
  /* height: 100%; */
  /* width: 95vw; */
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: center;
  row-gap: 50px;
  max-width: 1200px;
}

.appTile {
  position: relative;
  display: flex;
  width: 500px;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
  .appTile {
    height: 20vh;
    width: 90vw;
    margin-top: 50px;
    max-width: 95vw;
  }
  .appTile img {
    max-width: 80%;
    max-height: 80%;
  }
}

@media only screen and (min-width: 600px) {
  .appTile {
    height: 35vh;
  }
  .appTile img {
    max-width: 90%;
    max-height: 90%;
  }
}

.comingSoon {
  width: 50%;
  position: absolute;
  top: 70%;
  left: 70%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

#predictionsTile {
  display: flex;
  flex-direction: column;
  text-align: center;
}

#header1,
#header2 {
  margin-right: 10px;
  text-align: center;
  line-height: 10vh;
  color: #fcedd8;
  font-family: "Norican", cursive;
  font-weight: 700;
  text-shadow: 3px 3px 0px #eb452b, 6px 6px 0px #efa032, 9px 9px 0px #46b59b,
    12px 12px 0px #017e7f, 15px 15px 0px #052939, 18px 18px 0px #c11a2b;
  text-decoration: none;
}

a:link, a:visited{
  text-decoration: none;
}


@media only screen and (min-width: 600px) {
  #header1 {
    font-size: 8rem;
    padding-right: 20%;
  }

  #header2 {
    font-size: 4rem;
    padding-left: 20%;
  }
}

@media only screen and (max-width: 600px) {
  #header1,
  #header2 {
    line-height: 8vh;
  }

  #header1 {
    font-size: 6rem;
  }

  #header2 {
    font-size: 3rem;
  }
}
