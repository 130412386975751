@import url('https://fonts.googleapis.com/css?family=Raleway&display=swap');
body {
	background-color: #111;
}

.App-header{
    padding-top: 50px;
	padding-bottom: 50px;
}

.title {
	font-family: "Raleway", sans-serif;
	text-align: center;
	color: #FFF;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
    height: 90%;
	letter-spacing: 15px;
}

h1 {
	background-image: url(https://media.giphy.com/media/26BROrSHlmyzzHf3i/giphy.gif);
	background-size: cover;
	color: transparent;
	-moz-background-clip: text;
	-webkit-background-clip: text;
	text-transform: uppercase;
	font-size: 60px;
	line-height: 1;
	margin: 10px 0;
}
/* styling my button */

.white-mode {
	text-decoration: none;
	padding: 7px 10px;
	background-color: #122;
	border-radius: 3px;
	color: #FFF;
	transition: .35s ease-in-out;
	position: absolute;
	left: 15px;
	bottom: 15px;
	font-family: "Montserrat";
}

.white-mode:hover {
	background-color: #FFF;
	color: #122;
}

@media only screen and (max-width: 600px) {

    h1 {
        font-size: 40px;
        line-height: 1;;
    }
}